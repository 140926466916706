<template>
    <div>
        <!-- LOCATION PERMISSION -->
        <section v-if="showLocationRequired" class="enable-location py-5">
            <div class="py-5">
                <div class="py-5">
                    <div class="pt-5 fs-2">Enable Location</div>
                    <p class="text-start mt-5 mb-5 row">
                        <span class="col-12">
                            🌍 To enhance your experience, we'd like to access your location.<br/>
                            📍 Here's how:<br/>
                        </span>
                        <span class="col-12">
                            - **Google Chrome**: Click the three dots in the upper-right corner, go to Settings > Privacy
                            and security > Site settings > Location.<br/>
                        </span>
                        <span class="col-12">
                            - **Mozilla Firefox**: Click the three lines in the upper-right corner, go to Options > Privacy
                            & Security > Permissions > Location.<br/>
                        </span>
                        <span class="col-12">
                            - **Microsoft Edge**: Click the three dots in the upper-right corner, go to Settings > Cookies
                            and site permissions > Location.<br/>
                        </span>
                        <span class="col-12">
                            - **Safari (macOS)**: Go to Safari > Preferences > Websites > Location.<br/>
                        </span>
                        <span class="col-12">
                            🔒 We respect your privacy, and your location data will only be used to provide you with
                            relevant information. You can change your preferences anytime in your browser settings.<br/>
                        </span>
                        <span class="col-12">
                            🚀 Thank you for enabling location access!
                        </span>
                    </p>
                    <button @click="showLocationRequired=false" type="button"
                            class="btn btn-danger text-white col-4 p-2">Okay
                    </button>
                </div>
            </div>
        </section>

        <!-- REGISTRATION -->
        <section id="form-bg">
            <div class="container pt-5">
                <div class="section-title">
                    <h2>{{ registrationContents.title }}</h2>
                    <span class="line-bar">...</span>
                </div>

                <!-- REGISTRATION FORM HERE -->
                <form id="basicDetailForm" @submit.prevent="validateAndProceed" class="custom-form" name="custom-form">
                    <div v-if="page===1" class="row">
                        <div class="col-md-12 text-start">
                            <div class="fs-5 fw-bold text-white mb-2">
                                {{ registrationFormContents.basicDetailsTitle }}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="text-start text-white small">{{ registrationFormContents.name }}</div>
                            <input aria-label="" type="text" class="form-control"
                                   :placeholder="registrationFormContents.name"
                                   name="name" id="name" v-model="model.name" required :disabled="loading">
                        </div>
                        <div class="col-md-4">
                            <div class="text-start text-white small">{{ registrationFormContents.phone }}</div>
                            <input @input="validation.phoneExists = false" @keydown="validateNumberInput" type="text"
                                   name="phone" class="form-control" :placeholder="registrationFormContents.phone"
                                   maxlength="10" v-model="model.phone" required :disabled="loading">
                            <p v-if="validation.phoneExists" class="text-start fw-bold text-danger">
                                {{ registrationFormContents.phoneValidation }}</p>
                        </div>
                        <div class="col-md-4">
                            <div class="text-start text-white small">{{ registrationFormContents.age }}</div>
                            <input type="number" class="form-control" :placeholder="registrationFormContents.age"
                                   v-model="model.age" name="age" required :disabled="loading">
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="text-start text-white small">{{ registrationFormContents.gender }}</div>
                            <select class="form-select" name="gender"
                                    id="gender" v-model="model.gender" required :disabled="loading">
                                <option value="" disabled selected>Select {{ registrationFormContents.gender }}</option>
                                <option v-for="(i, index) in genderOptions" :key="'gender' + index"
                                        :value="i.id">
                                    {{ i.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="text-start text-white small">{{ registrationFormContents.maritalStatus }}</div>
                            <select class="form-select" name="marital_status" id="marital_status"
                                    v-model="model.marital_status" required :disabled="loading">
                                <option value="" disabled selected>Select {{
                                        registrationFormContents.maritalStatus
                                    }}
                                </option>
                                <option v-for="(i, index) in maritalStatusOptions" :key="'marital_status' + index"
                                        :value="i.id">
                                    {{ i.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-12 text-start mt-3">
                            <div class="fs-5 fw-bold text-white mb-2">
                                {{ registrationFormContents.nativeAddressTitleText }}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="text-start text-white small">
                                {{ registrationFormContents.nativeLocality }}
                            </div>
                            <input type="text" class="form-control"
                                   :placeholder="registrationFormContents.nativeLocality"
                                   v-model="model.native_locality" name="native_locality" required :disabled="loading">
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="text-start text-white small">{{ registrationFormContents.nativeState }}</div>
                            <select class="form-select" @change="model.native_district = ''"
                                    v-model="model.native_state" required :disabled="loading">
                                <option value="" disabled selected>
                                    Select {{ registrationFormContents.nativeState }}
                                </option>
                                <option v-for="(i, index) in stateDistrictOptions" :key="'native_state' + index"
                                        :value="i.id">
                                    {{ i.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="text-start text-white small">
                                {{ registrationFormContents.nativeDistrict }}
                            </div>
                            <select class="form-select" v-model="model.native_district" required :disabled="loading">
                                <option value="" disabled selected>
                                    Select {{ registrationFormContents.nativeDistrict }}
                                </option>
                                <option v-for="(i, index) in getDistrictOption(model.native_state)"
                                        :key="'native_district' + index" :value="i.id">
                                    {{ i.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-12 text-start">
                            <div class="fs-5 fw-bold text-white mb-2">
                                {{ registrationFormContents.keralaAddressTitleText }}
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="text-start text-white small">
                                {{ registrationFormContents.keralaLocality }}
                            </div>
                            <input type="text" class="form-control"
                                   :placeholder="registrationFormContents.keralaLocality"
                                   v-model="model.kerala_locality" name="kerala_locality" required :disabled="loading">
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="text-start text-white small">
                                {{ registrationFormContents.keralaDistrict }}
                            </div>
                            <select class="form-select" v-model="model.kerala_district" required :disabled="loading">
                                <option value="" disabled selected>
                                    Select {{ registrationFormContents.keralaDistrict }}
                                </option>
                                <option v-for="(i, index) in getDistrictOption(17)" :key="'kerala_district' + index"
                                        :value="i.id">
                                    {{ i.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="text-start text-white small">
                                {{ registrationFormContents.durationInKerala }}
                            </div>
                            <select class="form-select" name="duration_in_kerala" id="duration_in_kerala"
                                    v-model="model.duration_in_kerala"
                                    required :disabled="loading">
                                <option value="" disabled selected>
                                    Select {{ registrationFormContents.durationInKerala }}
                                </option>
                                <option v-for="(i, index) in durationOptions"
                                        :key="'duration' + index" :value="i.id">
                                    {{ i.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-12 text-center d-none d-sm-block">
                            <button type="submit" class="btn btn-danger text-white col-2 p-2">
                                {{ registrationFormContents.submitButton1 }}
                            </button>
                        </div>
                        <div class="col-md-12 text-center d-sm-none">
                            <button type="submit" class="btn btn-danger text-white col-12 p-2">
                                {{ registrationFormContents.submitButton1 }}
                            </button>
                        </div>
                    </div>
                </form>
                <form id="occupationForm" class="custom-form" name="custom-form">
                    <div v-if="page===2" class="row">
                        <div class="col-md-12 text-start">
                            <div class="fs-5 fw-bold text-white mb-2">
                                {{ registrationFormContents.occupationTitleText }}
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="text-start text-white small">
                                {{ registrationFormContents.occupationCategory }}
                            </div>
                            <select class="form-select" name="occupation_category" id="occupation_category"
                                    v-model="model.occupation_category"
                                    required :disabled="loading"
                                    @change="setOccupationOptions(model.occupation_category)">
                                <option value="" disabled selected>
                                    Select {{ registrationFormContents.occupationCategory }}
                                </option>
                                <option v-for="(i, index) in occupationCategoryOptions" :key="'cat' + index"
                                        :value="i.id">
                                    {{ i.category }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="text-start text-white small">
                                {{ registrationFormContents.occupation }}
                            </div>
                            <select class="form-select" name="occupation" id="occupation" v-model="model.occupation"
                                    required :disabled="loading">
                                <option v-if="occupationOptions.length===0" value="" disabled>
                                    {{ registrationFormContents.occupationSelectMessage }}
                                </option>
                                <option value="" disabled selected>
                                    Select {{ registrationFormContents.occupation }}
                                </option>
                                <option v-for="(i, index) in occupationOptions" :key="'ocp' + index"
                                        :value="i.id">
                                    {{ i.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-12 mb-3">
                            <div class="card p-2">
                                <div class="text-start">
                                    <div class="fs-5 fw-bold mb-2">
                                        {{ registrationFormContents.medicalHistoryTitleText }}
                                    </div>
                                </div>
                                <p v-if="!model.occupation_category">
                                    {{ registrationFormContents.medicalHistoryWarningMessage }}
                                </p>
                                <div v-else>
                                    <div v-for="(question, index) in currentQuestions" :key="'Q-' + index"
                                         class="bg-body-secondary mb-3 rounded-2 d-flex justify-content-between">
                                        <label class="pt-3 px-2 text-start" :for="'q_id' + index">
                                            {{ question }}
                                        </label>
                                        <div class="d-flex align-items-center">
                                            <input type="checkbox" :id="'q_id' + index" class="form-check-inline"
                                                   v-model="model['question_' + (index + 1)]" required
                                                   :disabled="loading"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 btn-group-sm text-center d-none d-sm-block">
                            <button @click="page = 1" type="button" class="btn btn-secondary text-white col-2 p-2 m-1 mb-3">
                                {{ registrationFormContents.backButton }}
                            </button>
                            <button @click="registerUser" type="button"
                                    class="btn btn-danger text-white col-2 p-2 m-1 mb-3">
                                {{ registrationFormContents.submitButton2 }}
                            </button>
                        </div>
                        <div class="col-md-12 btn-group-sm text-center d-sm-none">
                            <button @click="page = 1" type="button"
                                    class="btn btn-secondary text-white col-12 p-2 m-1 mb-3">
                                {{ registrationFormContents.backButton }}
                            </button>
                            <button @click="registerUser" type="button"
                                    class="btn btn-danger text-white col-12 p-2 m-1 mb-3">
                                {{ registrationFormContents.submitButton2 }}
                            </button>
                        </div>
                    </div>
                </form>
                <registration-success :data="model" v-if="page===3"/>
            </div>
        </section>

        <!-- FOOTER -->
        <website-footer/>
    </div>
</template>

<script>

import axios from 'axios';
import urls from '@/data/urls';
import translator from '@/views/Website/Translations/translator';
import RegistrationSuccess from '@/views/Website/Components/RegistrationSuccess';
import WebsiteFooter from '@/components/website/website-footer';

export default {
    name       : 'Registration',
    components : { WebsiteFooter, RegistrationSuccess },
    data () {
        return {
            page                 : 1,
            fetchedLocation      : false,
            showLocationRequired : false,
            model                : {
                action              : 'Add',
                name                : '',
                phone               : '',
                age                 : '',
                gender              : '',
                marital_status      : '',
                native_locality     : '',
                native_district     : '',
                native_state        : '',
                kerala_locality     : '',
                kerala_district     : '',
                duration_in_kerala  : '',
                occupation_category : '',
                occupation          : '',
                question_1          : false,
                question_2          : false,
                question_3          : false,
                question_4          : false,
                question_5          : false,
                question_6          : false,
                question_7          : false,
                question_8          : false,
                question_9          : false,
                question_10         : false
            },
            registrationContents      : translator.registrationContents,
            registrationFormContents  : translator.registrationContents.formContents,
            maritalStatusOptions      : translator.maritalStatusOptions,
            genderOptions             : translator.genderOptions,
            durationOptions           : translator.durationOptions,
            stateDistrictOptions      : translator.stateDistrictOptions,
            occupationCategoryOptions : translator.occupationCategoryOptions,
            occupationOptions         : [],
            questionnaire             : translator.questionnaire,
            currentQuestions          : [],
            validation                : {
                name               : true,
                phone              : true,
                native_locality    : true,
                native_district    : true,
                native_state       : true,
                kerala_locality    : true,
                kerala_district    : true,
                age                : true,
                gender             : true,
                marital_status     : true,
                duration_in_kerala : true,
                phoneExists        : false
            },
            loading : false
        };
    },
    methods : {
        getDistrictOption (state) {
            if (state) {
                const item = this.stateDistrictOptions.filter(i => i.id === state);
                return item[0].districts;
            } else {
                return [];
            }
        },
        validateNumberInput (event) {
            const key = event.key;
            const keyCode = event.keyCode || event.which;
            if (!/^\d$/.test(key) && keyCode !== 8) {
                event.preventDefault();
            }
        },
        validateAndProceed () {
            if (!this.model.phone || !this.model.native_locality || !this.model.native_district ||
                !this.model.native_state || !this.model.kerala_locality || !this.model.kerala_district || !this.model.age ||
                !this.model.gender || !this.model.marital_status || !this.model.duration_in_kerala
            ) {
                this.validation.name = !this.model.name;
                this.validation.phone = !this.model.phone;
                this.validation.native_locality = !this.model.native_locality;
                this.validation.native_district = !this.model.native_district;
                this.validation.native_state = !this.model.native_state;
                this.validation.kerala_locality = !this.model.kerala_locality;
                this.validation.kerala_district = !this.model.kerala_district;
                this.validation.age = !this.model.age;
                this.validation.gender = !this.model.gender;
                this.validation.marital_status = !this.model.marital_status;
                this.validation.duration_in_kerala = !this.model.duration_in_kerala;
            } else {
                this.page = 2;
            }
        },
        setOccupationOptions (category) {
            this.setQuestions(category);
            this.model.occupation = '';
            const item = this.occupationCategoryOptions.filter(i => i.id === category);
            this.occupationOptions = item[0].occupations;
        },
        setQuestions (category) {
            const item = this.questionnaire.filter(i => i.category_id === category);
            this.currentQuestions = item[0].questions;
        },
        async checkLocation () {
            try {
                await this.getLocation();
                if (!this.fetchedLocation) {
                    this.showLocationRequired = true;
                } else {
                    await this.registerUser();
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        },
        successCallback (data) {
            this.model.latitude = data.coords.latitude;
            this.model.longitude = data.coords.longitude;
            this.fetchedLocation = true;
        },
        errorCallback () {
            console.log('Could not get Geolocation.');
        },
        getLocation () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.successCallback, this.errorCallback);
            } else {
                console.log('Geolocation is not supported by this browser.');
            }
        },
        async registerUser () {
            this.loading = true;
            try {
                const response = await axios.post(urls.registration, this.model,
                    { headers : { 'Content-Type' : 'multipart/form-data' } }
                );
                const json = response.data;
                if (json.success === true) {
                    this.page = 3;
                } else {
                    if (json.message) {
                        this.handleError(json.message);
                    } else if (json.response) {
                        this.handleError(json.response);
                    } else {
                        alert('Failed to submit your application, Please try again later.');
                    }
                }
            } catch (error) {
                console.error('An error occurred:', error);
            } finally {
                this.loading = false;
            }
        },
        handleError (error) {
            if (error === 'Phone Exists' || error === 'User Registration Already Exists') {
                this.validation.phoneExists = true;
                this.page = 1;
            } else {
                alert(error);
            }
        }
    }
};
</script>

<style scoped>

</style>
