<template>
    <div>
        <!-- HOME -->
        <section id="home">
            <div class="c-overlay"></div>
            <div class="container text-start">
                <div class="row">
                    <div class="col-md-7 col-sm-12">
                        <div class="home-info">
                            <h1 class="mb-2">
                                {{ homePageContents.bannerContent.welcomeText }}
                            </h1>
                            <div class="mb-2 fw-bolder">
                                {{ homePageContents.bannerContent.text1 }}
                            </div>
                            <div class="mb-2 small">
                                {{ homePageContents.bannerContent.text2 }}
                            </div>
                            <br/>
                            <a :href="'/web/register/'"
                               class="btn section-btn">
                                {{ homePageContents.bannerContent.registerNow }}
                            </a>
                            <div class="mt-3">
                                {{ homePageContents.bannerContent.callUsText1 }} (+91) 799 1411 9993, 871 477 0906
                                <small>{{ homePageContents.bannerContent.callUsText2 }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-12 c-select">
                        <div class="mb-2 text-white small">{{ homePageContents.languageContent.labelText }}</div>
                        <select class="form-select" v-model="languageSelected">
                            <option value="" disabled="" selected="selected">
                                {{ homePageContents.languageContent.selectLanguageText }}
                            </option>
                            <option v-for="(option, index) in languageOptions"
                                    :value="option.value" :key="index">
                                {{ option.label }}
                            </option>
                        </select>
                        <br/>
                        <button @click="setLanguage" class="btn btn-danger w-100" type="button">
                            {{ homePageContents.languageContent.submitButton }}
                        </button>
                    </div>
                </div>
            </div>
        </section>

        <!-- FOOTER -->
        <website-footer/>
    </div>
</template>

<script>

import { mapActions } from 'vuex';
import translator from '@/views/Website/Translations/translator';
import WebsiteFooter from '@/components/website/website-footer';

export default {
    name       : 'LandingPage',
    components : { WebsiteFooter },
    data () {
        return {
            homePageContents : translator.homePageContents,
            languageSelected : '',
            languageOptions  : [
                {
                    label : 'English',
                    value : 'english'
                },
                {
                    label : 'हिन्दी',
                    value : 'hindi'
                },
                {
                    label : 'বাংলা',
                    value : 'bengali'
                },
                {
                    label : 'অসমীয়া',
                    value : 'assamese'
                }
            ]
        };
    },
    mounted () {
        this.checkIfLanguageSelected();
    },
    methods : {
        ...mapActions(['setUser']),
        checkIfLanguageSelected () {
            const languageSelected = localStorage.getItem('languageSelected');
            if (languageSelected) {
                this.languageSelected = languageSelected;
            }
        },
        setLanguage () {
            this.setUser({ languageSelected : this.languageSelected });
            localStorage.setItem('languageSelected', this.languageSelected);
            this.$router.push({ path : '/web/', query : { _forceRefresh : true } });
        }
    }
};
</script>

<style scoped>

</style>
