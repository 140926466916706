<template>
    <div>
        <!-- HOME -->
        <section id="home">
            <div class="c-overlay"></div>
            <div class="container text-start">
                <div class="row">
                    <div class="col-md-7 col-sm-12">
                        <div class="home-info">
                            <h1 class="mb-2">
                                {{ homePageContents.bannerContent.welcomeText }}
                            </h1>
                            <div class="mb-2 fw-bolder">
                                {{ homePageContents.bannerContent.text1 }}
                            </div>
                            <div class="mb-2 small">
                                {{ homePageContents.bannerContent.text2 }}
                            </div>
                            <br/>
                            <a :href="'/web/register/'"
                               class="btn section-btn">
                                {{ homePageContents.bannerContent.registerNow }}
                            </a>
                            <div class="mt-3">
                                <small>{{ homePageContents.bannerContent.callUsText1 }}</small>
                                (+91) 799 1411 9993, 871 477 0906
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- ABOUT -->
        <section id="about">
            <div class="container">
                <div class="row">

                    <div class="col-md-12 col-sm-12">
                        <div class="about-info">
                            <div class="section-title">
                                <h2>{{ homePageContents.aboutContent.heading }}</h2>
                                <span class="line-bar">...</span>
                            </div>
                            <p>{{ homePageContents.aboutContent.text1 }}
                            </p>
                            <p>{{ homePageContents.aboutContent.text2 }}</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <!-- CONTACT -->
        <section id="form-bg">
            <div class="container">
                <div class="row">

                    <div class="col-md-12 col-sm-12 mt-2">
                        <div class="section-title">
                            <h2>{{ homePageContents.contactUs.title }}</h2>
                            <span class="line-bar">...</span>
                        </div>
                    </div>

                    <div class="col-md-3 col-sm-3 mt-5 d-none d-sm-block"></div>

                    <div class="col-md-6 col-sm-6 mt-3">

                        <!-- CONTACT FORM HERE -->
                        <c-form id="custom-form" :save-url="contactUsUrl" @formSuccess="formSuccess"
                                @formFailed="formFailed" v-slot="{model, loading}" :save-params="{action:'Add'}"
                                class="custom-form" name="custom-form">
                            <div class="col-md-12 col-md-12 mb-4">
                                <input type="text" class="form-control" :placeholder="contactFormLabels.name" id="name"
                                       name="name" v-model="model.name" :required="true" :disabled="loading">
                            </div>

                            <div class="col-md-12 col-md-12 mb-4">
                                <input @input="validation.emailExists = false" type="email" class="form-control"
                                       :placeholder="contactFormLabels.email" id="email"
                                       v-model="model.email" :required="true" :disabled="loading">
                                <p v-if="validation.emailExists" class="text-start fw-bold text-danger">
                                    Account with E-Mail ID already exists.</p>
                            </div>

                            <div class="col-md-12 col-md-12 mb-4">
                                <input @input="validation.phoneExists = false" @keydown="validateNumberInput"
                                       type="text" name="phone"
                                       class="form-control" :placeholder="contactFormLabels.phone" maxlength="10"
                                       v-model="model.phone" required :disabled="loading">
                                <p v-if="validation.phoneExists" class="text-start fw-bold text-danger">
                                    Account with Phone number already exists.</p>
                            </div>

                            <div class="col-md-12 col-sm-12 mb-4">
                                <textarea class="form-control" rows="6" :placeholder="contactFormLabels.message"
                                          id="message" v-model="model.message" :required="false" :disabled="loading"/>
                            </div>

                            <div class="col-md-12 col-sm-12">
                                <button type="submit" class="btn btn-danger w-100 p-2">
                                    {{ contactFormLabels.submitButton }}
                                </button>
                            </div>
                        </c-form>
                    </div>

                    <div class="col-md-3 col-sm-3 mt-5 d-none d-sm-block"></div>

                </div>
            </div>
        </section>
        <Footer/>
    </div>
</template>

<script>

import urls from '@/data/urls';
import translator from '@/views/Website/Translations/translator';
import Footer from '@/components/website/website-footer';

export default {
    name       : 'WebsiteHome',
    components : { Footer },
    data () {
        return {
            model             : {},
            generalContents   : translator.generalContents,
            homePageContents  : translator.homePageContents,
            contactFormLabels : translator.homePageContents.contactUs.formContents,
            contactUsUrl      : urls.contactUs,
            validation        : {
                phoneExists : false,
                emailExists : false
            }
        };
    },
    mounted () {
        this.checkForceRefresh();
    },
    methods : {
        checkForceRefresh () {
            const isForceRefresh = this.$route.query._forceRefresh === 'true';
            if (isForceRefresh) {
                window.location.href = '/web/';
            }
        },
        validateNumberInput (event) {
            const key = event.key;
            const keyCode = event.keyCode || event.which;
            if (!/^\d$/.test(key) && keyCode !== 8) {
                event.preventDefault();
            }
        },
        formSuccess (response) {
            if (response.message) {
                alert(response.message);
            } else {
                alert('Submitted Successfully');
            }
            this.$router.go();
        },
        formFailed (response) {
            if (response.data.message) {
                this.handleError(response.data.message);
            } else {
                alert('Failed to submit your application, Please try again later.');
            }
        },
        handleError (error) {
            if (error === 'Phone Exists') {
                this.validation.phoneExists = true;
            } else if (error === 'E-Mail Exists') {
                this.validation.emailExists = true;
            } else if (error === 'Phone And E-Mail Exists') {
                this.validation.phoneExists = true;
                this.validation.emailExists = true;
            } else {
                alert(error);
            }
        }
    }
};
</script>

<style scoped>

</style>
