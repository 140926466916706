<template>
    <div class="py-3">
        <div class="text-center text-white">
            <i class="fa fa-check-circle fs-1 mb-3"/>
            <div class="h4">{{ registrationSuccessContents.successMessage }}</div>
            <div class="small">{{ registrationSuccessContents.thanks }}</div>
            <div v-if="data" class="small mt-2 mb-3">
                <span class="text-danger fw-bolder">{{ registrationSuccessContents.noteTitle }} : </span>
                {{ registrationSuccessContents.noteContent }}
            </div>
        </div>
        <div v-if="data">
            <div class="row">
                <div class="col-md-12 text-start">
                    <div class="fs-5 fw-bold text-white mb-2">
                        {{ registrationFormContents.basicDetailsTitle }}
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-start text-white small">{{ registrationFormContents.name }}</div>
                    <input aria-label="" type="text" class="form-control"
                           :placeholder="registrationFormContents.name"
                           name="name" id="name" v-model="data.name" :disabled="true">
                </div>
                <div class="col-md-4">
                    <div class="text-start text-white small">{{ registrationFormContents.phone }}</div>
                    <input type="text" name="phone" class="form-control" :placeholder="registrationFormContents.phone"
                           maxlength="10" v-model="data.phone" :disabled="true">
                </div>
                <div class="col-md-4">
                    <div class="text-start text-white small">{{ registrationFormContents.age }}</div>
                    <input type="number" class="form-control" :placeholder="registrationFormContents.age"
                           v-model="data.age" name="age" :disabled="true">
                </div>
                <div class="col-md-4">
                    <div class="text-start text-white small">{{ registrationFormContents.gender }}</div>
                    <select class="form-control" :placeholder="registrationFormContents.gender" name="gender"
                            id="gender" v-model="data.gender" :disabled="true">
                        <option v-for="(i, index) in genderOptions" :key="'gender' + index"
                                :value="i.id">
                            {{ i.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <div class="text-start text-white small">{{ registrationFormContents.maritalStatus }}</div>
                    <select class="form-control" :placeholder="registrationFormContents.maritalStatus"
                            name="marital_status" id="marital_status" v-model="data.marital_status" :disabled="true">
                        <option v-for="(i, index) in maritalStatusOptions" :key="'marital_status' + index"
                                :value="i.id">
                            {{ i.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-12 text-start">
                    <div class="fs-5 fw-bold text-white mb-2">
                        {{ registrationFormContents.nativeAddressTitleText }}
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-start text-white small">
                        {{ registrationFormContents.nativeLocality }}
                    </div>
                    <input type="text" class="form-control"
                           :placeholder="registrationFormContents.nativeLocality"
                           v-model="data.native_locality" name="native_locality" :disabled="true">
                </div>
                <div class="col-md-4">
                    <div class="text-start text-white small">{{ registrationFormContents.nativeState }}</div>
                    <select class="form-control" :placeholder="registrationFormContents.nativeState"
                            v-model="data.native_state" :disabled="true">
                        <option v-for="(i, index) in stateDistrictOptions" :key="'native_state' + index"
                                :value="i.id">
                            {{ i.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <div class="text-start text-white small">
                        {{ registrationFormContents.nativeDistrict }}
                    </div>
                    <select class="form-control" :placeholder="registrationFormContents.nativeDistrict"
                            v-model="data.native_district" :disabled="true">
                        <option v-for="(i, index) in getDistrictOption(data.native_state)"
                                :key="'native_district' + index" :value="i.id">
                            {{ i.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-12 text-start">
                    <div class="fs-5 fw-bold text-white mb-2">
                        {{ registrationFormContents.keralaAddressTitleText }}
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-start text-white small">
                        {{ registrationFormContents.keralaLocality }}
                    </div>
                    <input type="text" class="form-control"
                           :placeholder="registrationFormContents.keralaLocality"
                           v-model="data.kerala_locality" name="kerala_locality" :disabled="true">
                </div>
                <div class="col-md-4">
                    <div class="text-start text-white small">
                        {{ registrationFormContents.keralaDistrict }}
                    </div>
                    <select class="form-control" :placeholder="registrationFormContents.keralaDistrict"
                            v-model="data.kerala_district" :disabled="true">
                        <option v-for="(i, index) in getDistrictOption(17)" :key="'kerala_district' + index"
                                :value="i.id">
                            {{ i.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <div class="text-start text-white small">
                        {{ registrationFormContents.durationInKerala }}
                    </div>
                    <select class="form-control" :placeholder="registrationFormContents.durationInKerala"
                            name="duration_in_kerala" id="duration_in_kerala" v-model="data.duration_in_kerala"
                            :disabled="true">
                        <option v-for="(i, index) in durationOptions"
                                :key="'duration' + index" :value="i.id">
                            {{ i.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-start">
                    <div class="fs-5 fw-bold text-white mb-2">
                        {{ registrationFormContents.occupationTitleText }}
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-start text-white small">
                        {{ registrationFormContents.occupationCategory }}
                    </div>
                    <select class="form-control" :placeholder="registrationFormContents.occupationCategory"
                            name="occupation_category" id="occupation_category"
                            v-model="data.occupation_category" :disabled="true">
                        <option v-for="(i, index) in occupationCategoryOptions" :key="'cat' + index"
                                :value="i.id">
                            {{ i.category }}
                        </option>
                    </select>
                </div>
                <div class="col-md-6">
                    <div class="text-start text-white small">
                        {{ registrationFormContents.occupation }}
                    </div>
                    <select class="form-control" :placeholder="registrationFormContents.occupation"
                            name="occupation" id="occupation" v-model="data.occupation"
                            :disabled="true">
                        <option v-if="occupationOptions.length===0" value="" disabled>
                            {{ registrationFormContents.occupationSelectMessage }}
                        </option>
                        <option v-for="(i, index) in occupationOptions" :key="'ocp' + index"
                                :value="i.id">
                            {{ i.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-12 mb-2">
                    <div class="card p-2">
                        <div class="text-start">
                            <div class="fs-5 fw-bold mb-2">
                                {{ registrationFormContents.medicalHistoryTitleText }}
                            </div>
                        </div>
                        <div class="col-md-12 mb-2 mt-2">
                            <div class="card p-2">
                                <div v-for="(question, index) in currentQuestions" :key="'Q-' + index"
                                     class="bg-body-secondary mb-3 rounded-2 d-flex justify-content-between">
                                    <label class="pt-3 px-2 text-start" :for="'q_id' + index">
                                        {{ question }}
                                    </label>
                                    <div class="d-flex align-items-center">
                                        <i v-if="data['question_' + (index + 1)]" class="p-3 fa fa-check text-success"/>
                                        <i v-else class="p-3 fa fa-close text-danger"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mt-3">
            <a :href="'/web/'" class="btn btn-danger">{{ registrationFormContents.okayButton }}</a>
        </div>
    </div>
</template>

<script>
import translator from '@/views/Website/Translations/translator';

export default {
    name  : 'RegistrationSuccess',
    props : {
        data : {
            type : Object
        }
    },
    data () {
        return {
            model                       : {},
            registrationContents        : translator.registrationContents,
            registrationFormContents    : translator.registrationContents.formContents,
            registrationSuccessContents : translator.registrationContents.successPageContents,
            maritalStatusOptions        : translator.maritalStatusOptions,
            genderOptions               : translator.genderOptions,
            durationOptions             : translator.durationOptions,
            stateDistrictOptions        : translator.stateDistrictOptions,
            occupationCategoryOptions   : translator.occupationCategoryOptions,
            occupationOptions           : [],
            questionnaire               : translator.questionnaire,
            currentQuestions            : []
        };
    },
    mounted () {
        this.setOccupationOptions();
    },
    methods : {
        getDistrictOption (state) {
            if (state) {
                const item = this.stateDistrictOptions.filter(i => i.id === state);
                return item[0].districts;
            } else {
                return [];
            }
        },
        setOccupationOptions () {
            this.setQuestions(this.data.occupation_category);
            const item = this.occupationCategoryOptions.filter(i => i.id === this.data.occupation_category);
            this.occupationOptions = item[0].occupations;
        },
        setQuestions (category) {
            const item = this.questionnaire.filter(i => i.category_id === category);
            this.currentQuestions = item[0].questions;
        }
    }
};
</script>

<style scoped>

</style>
