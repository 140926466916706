<template>
    <!-- FOOTER -->
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <div class="copyright-text">
                        <p>{{ generalContents.footerContents.copyright }} &copy; 2023
                            {{ generalContents.menuContents.projectTitle }}</p>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <div class="phone-contact">
                        <p>{{ generalContents.footerContents.callUs }} <span>(+91) 799 1411 9993, 871 477 0906</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import translator from '@/views/Website/Translations/translator';

export default {
    name : 'website-footer',
    data () {
        return {
            generalContents : translator.generalContents
        };
    }
};
</script>

<style scoped>

</style>
